export class FieldWiseControl {
  role: string;
  screen: object;
  event: string;
  fields: string;
  name: string;
  label: string;
  dateCreation: Date;
  version: number;
  companyCode: string;
  status: string;
  environment: string;
  constructor(rawData: { [key in string]: any }) {
    this.role = rawData.role;
    this.screen = rawData.screen;
    this.event = rawData.event;
    this.fields = rawData.fields;
    this.label = rawData.label;
    this.name = rawData.name;
    this.dateCreation = rawData.dateCreation;
    this.version = rawData.version;
    this.companyCode = rawData.companyCode;
    this.status = rawData.status;
    this.environment = rawData.environment;
  }
}

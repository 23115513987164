import { makeObservable, action, observable, computed } from 'mobx';
import { FieldWiseControl } from '../models';
// import {  } from '../services';

export class FieldWiseControlStore {
  fieldWiseControl!: FieldWiseControl;
  listFieldWiseControl!: FieldWiseControl[];
  listFieldWiseControlCount: number = 0;

  constructor() {
    this.fieldWiseControl = new FieldWiseControl({});
    this.reset();
    makeObservable<FieldWiseControlStore, any>(this, {
      fieldWiseControl: observable,
      listFieldWiseControl: observable,
      listFieldWiseControlCount: observable,

      FieldWiseControlService: computed,
      fetchListFieldWiseControl: action,
      updateFieldWiseControl: action,
      reset: action,
    });
  }

  reset() {
    this.fieldWiseControl = new FieldWiseControl({});
    this.listFieldWiseControl = [];
    this.listFieldWiseControlCount = 0;
    this.fieldWiseControl = {
      ...this.fieldWiseControl,
      dateCreation: new Date(),
      version: 1,
    };
  }

  get FieldWiseControlService() {
    return;
  }

  fetchListFieldWiseControl(page?, limit?) {}

  updateFieldWiseControl = (fieldWiseControl: FieldWiseControl) => {
    this.fieldWiseControl = fieldWiseControl;
  };
}
